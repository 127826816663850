<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <div class="box">
      <Title title="发票列表"/>
      <div class="ct_box">
        <div class="search_status_box flex">
          <div class="search_box flex">
            <order_time @get_time="get_time"/>
          </div>
          <orderStatus width="300" :status_list="status_list" :order_status="status"
                       @status_tab="status_tab"/>
          <!-- <ul class="status_ul flex">
            <li :class="status.title == item.title ? 'active' : ''" v-for="(item, index) in status_list"
              :key="index" @click="statusShow(item)">
              {{ item.title }}
            </li>
          </ul> -->
        </div>
        <table class="table">
          <thead>
          <tr>
            <!-- <td>单号</td> -->
            <td>类型</td>
            <td>抬头</td>
            <td>开票金额</td>
            <td>状态</td>
            <td>申请时间</td>
            <!-- <td>详情</td> -->
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in tableData" :key="index">
            <!-- <td>{{ item.order_ids }}</td> -->
            <td>电子发票(1%税点)
            </td>
            <td>{{ item.header }}</td>
            <td>{{ item.amount }}</td>
            <td :style="{ color: status_list[item.status + 1].color }">
              {{ status_list[item.status + 1].title }}
            </td>
            <!-- <td></td> -->
            <td>{{ item.ctime }}</td>
          </tr>
          </tbody>
        </table>
        <!-- 分页器 -->
        <pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page"/>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '@/components/UserInfoTitle'
import order_time from '@/components/order_time'
import orderStatus from '@/components/orderStatus'

export default {
  components: {
    Title,
    order_time,
    orderStatus
  },
  mounted() {
    this.get_list()
  },
  data() {
    return {
      time: [],
      status_list: [
        {
          id: '',
          title: '全部',
        },
        {
          id: 0,
          title: '待审核',
          color: '#333'
        }, {
          id: 1,
          title: '开票中',
          color: '#FF8400'
        }, {
          id: 2,
          title: '已完成',
          color: '#00B052'
        }, {
          id: 3,
          title: '被拒单',
          color: '#FF0000'
        }],
      status: '',
      tableData: [],
      page: 1,
      total_page: 0, //分页总页数
      count: 0, //总条数
      fullscreenLoading: false, //loding

    }
  },
  methods: {
    pageChange(pageVal) {
      this.page = pageVal
      this.get_list()
    },
    get_time(time) {
      this.time = time || []
      this.pageChange(1)
    },
    status_tab(id) {
      this.status = id
      this.pageChange(1)
    },
    get_list() {
      this.fullscreenLoading = true
      let data = {}
      if (this.status !== '') {
        data.status = this.status
      }
      if (this.time.length) {
        data.start_time = this.time[0] / 1000
        data.end_tiem = this.time[1] / 1000
      }
      this.curlPost('/api/users/invoice', data).then(res => {
        if (res.data.code) {
          this.tableData = res.data.data.list
          this.total_page = res.data.data.total_page
          this.count = res.data.data.count
        }
        this.fullscreenLoading = false
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/Invoice'
</style>